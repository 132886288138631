
import Vue from 'vue';
import utils from '../../../util';
import { validProducts } from '../../components/summary/utils';
import { Product, ProductTactic } from '../../../types/advertisers';

export default Vue.extend({
  name: 'ProductList',
  props: ['productData', 'advertiser', 'currentSection', 'advertiserName', 'product', 'loading'],
  components: {
    ExportMenu: () => import('../../components/toolbar/menus/exportMenu.vue'),
  },
  data: (): {
    selectedItem: string | null;
    selectedFriendlyName: string | null;
    search: string | null;
  } => ({
    selectedItem: null,
    selectedFriendlyName: null,
    search: null,
  }),
  computed: {
    data(): Array<Product> | null {
      if (!this.productData || !this.productData?.length) {
        return null;
      }
      return utils.groupTactics(this.productData);
    },
    productCount(): string | null {
      return this.productData?.length ? `(${this.productData.length})` : null;
    },
    filteredProducts(): Array<Product> {
      const searchText = this.search ? this.search.toLowerCase() : null;

      // recursive function to loop through nested ad data
      function search(product) {
        const lowerProductName: string | null = product.name ? product.name.toLowerCase() : null;
        const id = product.id ? product.id.toLowerCase() : null;
        // if it has tactics, loop through them
        if (Array.isArray(product.tabArray)) {
          const tactics = product.tabArray.filter(search);
          if (tactics.length) {
            product.tabArray = tactics;
            return product;
          } else {
            delete product.tabArray;
            if (lowerProductName?.includes(searchText)) {
              return product;
            }
            return null;
          }
        }
        if ((lowerProductName && lowerProductName.includes(searchText)) || (id && id.includes(searchText))) {
          // we have a match
          return product;
        } else {
          return null;
        }
      }
      if (this.isSearchActive) {
        // only search when at least 3 characters

        // create a tmp object to avoid modifying the source
        const tmp = JSON.parse(JSON.stringify(this.data));
        return tmp.filter(search);
      } else return this.data; // return unmodified source
    },
    isSearchActive(): boolean {
      const searchText = this.search ? this.search.toLowerCase() : null;
      return searchText?.length > 1;
    },
    message(): string {
      return this.productData ? 'No products found' : 'Please select an advertiser';
    },
    formatedExportData() {
      if (!this.selectedItem) return null;
      return {
        advertiserId: this.advertiser,
        advertiserName: this.advertiserName,
        campaigns: '',
        daterange: 'alltime',
        componentId: `summaryController:${this.selectedItem}`,
        componentTitle: `${this.selectedFriendlyName}`,
      };
    },
  },
  methods: {
    isSelected(id: string): boolean {
      return validProducts.includes(id) && this.product === id;
    },
    productSelect(productId: string, friendlyName?: string): void {
      this.selectedFriendlyName = friendlyName || '';
      this.$emit('productSelect', productId);
    },
    openTactic(product: ProductTactic): void {
      this.$emit('openTactic', product);
    },
    exportProduct(): void {
      // todo: exports
    },
  },
  watch: {
    selectedItem: {
      handler(): void {
        Vue.nextTick(() => {
          this.selectedItem = this.product;
        });
      },
    },
    productData: {
      handler(): void {
        if (this.data && this.data[0]?.tabArray[0]) {
          this.productSelect(this.data[0]?.tabArray[0].id);
          Vue.nextTick(() => {
            // default to first tactic
            this.selectedItem = this.data[0]?.tabArray[0].id;
          });
        }
        this.search = null;
        this.selectedItem = null;
      },
    },
  },
});
